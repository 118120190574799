import React, { useState, useEffect } from 'react'
import { getStrapiHero } from './data/geStrapiHero'
import { Link } from 'react-scroll'

const Hero = () => {
  const [strapiHeroContent, setStrapiHeroContent] = useState([])
  const collectionData = 'heroes?populate[hero_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiHeroContent(data)
    }
    fetchData()
  }, [])

  return (
    <div className='bg-neutral-100 dark:bg-slate-800'>
      <header id='Home'>
        {strapiHeroContent?.map(({ hero_title, hero_subtitle, id, background, text_button }) => (
          <div className='w-full bg-cover bg-center' style={{ backgroundImage: `url(${background})`, height: '32rem' }} key={id}>
            <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
              <div className='text-center'>
                <h1 className='text-white text-2xl font-semibold uppercase md:text-3xl pb-2'>{ hero_title }</h1>
                <p className='text-white text-lg font-medium md:text-xl pb-2 capitalize'>{ hero_subtitle}</p>
                <div className='flex items-center justify-center'>
                <Link
                  spy
                  smooth
                  to='contact'
                  className='animate-bounce duration-0 mt-4 delay-0 shadow-xl text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                >
                  {text_button}
                </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </header>
    </div>
  )
}

export default Hero
