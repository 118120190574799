import IconFacebook from '../assets/icon-facebook.svg';
import { Link } from 'react-scroll'
import { ReactSVG } from 'react-svg'

const Footer = () => {
  const LinkForComputer = ({ text, path }) => (
    <Link to={path} spy smooth className='hover:border-b-2 hover:border-b-sky-400 text-gray-500 dark:text-white hover:text-sj-900 dark:hover:text-gray-400 transition duration-300'>{text}</Link>
  )

  return (
    <div className='footer bg-blue-900'>
      <div className='footer__container'>
        <div className='footer__links-container'>
          <div className='footer__items-wrapper'>
            <div className='footer__links-items'>
              <h2>MH Image</h2>
              <LinkForComputer text='Home' path='home' />
              <LinkForComputer text='About Us' path='about' />
              <LinkForComputer text='Services' path='services' />
            </div>
          </div>

          <div className='footer__items-wrapper'>
            <div className='footer__links-items '>
              <h2>Contact Us</h2>
              <LinkForComputer text='Send Mail' path='contact' />
            </div>
          </div>
        </div>

        <div className='footer__icons-container'>
          <a href='https://www.facebook.com/'>
            <ReactSVG src={IconFacebook} className="footer__icons-separation" />
          </a>
        </div>
      </div>
      <div className='footer__website-right'>
        <small>
          &#169; {(new Date().getFullYear())} Telluride Digital Works - All Rights Reserved
        </small>
      </div>
    </div>
  )
}

export default Footer
