import React from 'react'
import '../src/stylesheets/application.scss'
import './index.css'
import Header from './components/Header'
import Hero from './components/Hero'
import About from './components/About'
import Services from './components/Services'
import Contact from './components/FormContact'
import FindUs from './components/FindUs'
import Footer from './components/Footer'

function App() {
  return (
    <>
      <div className='h-screen justify-between antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-800'>
        <Header />
        <Hero />
        <About />
        <Services />
        <Contact />
        <FindUs />
        <Footer />
      </div>
    </>
  );
}

export default App;
