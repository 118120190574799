import React, { useState, useEffect } from 'react'
import Information from './Information'
import { getStrapiAbout } from './data/getStrapiAbout'

const About = () => {
  const [strapiAboutContent, setStrapiAboutContent] = useState([])
  const collectionData = 'abouts?populate[about_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiAbout(collectionData)
      setStrapiAboutContent(data)
    }
    fetchData()
  }, [])

  return (
    <>
      {strapiAboutContent.map(({ id, about_title, about_subtitle, about_content, background }) => (
        <div className='w-full dark:bg-slate-800' id='About Us' key={id}>
          <div className='mx-auto max-w-6xl mb-20 pt-10 container'>
            <Information
              title={about_title}
              subtitle={about_subtitle}
              content={about_content}
            />
          </div>

          <div className='bg-no-repeat bg-cover bg-center w-full' style={{ backgroundImage: `url(${background})`, height: '35rem' }}>
            <div className='h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'></div>
          </div>
        </div>
      ))}
    </>
  )
}
export default About
