import { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import ButtonTheme from './ButtonDarkmode'
import { getStrapiHeader } from './data/getStrapiHeader'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'headers?[populate][header_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHeader(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const LinkForComputer = ({ path }) => (
    <Link
      to={path}
      spy
      smooth
      className="py-4 px-6 hover:border-b-2 hover:border-b-sky-400 text-white dark:text-white font-semibold hover:text-sj-900 dark:hover:text-gray-400 transition duration-300">
      {path}
    </Link>
  )

  const LinkForMobile = ({ path }) => (
    <Link
      to={path}
      spy
      smooth
      className="block text-sm text-white px-2 py-4 hover:border-b-2 hover:border-b-sky-400 transition duration-300">
      {path}
    </Link>
  )

  return (
    <nav className='navbar-container bg-blue-900 dark:bg-slate-900/50 shadow-lg'>
      {strapiContent?.map(({id, header_name, header_logo, sections_on_page }) => (
        <div key={id} className='container mx-auto'>
          <div className='flex custom-ctn justify-between'>
            <div>
              <Link to='home' className='flex items-center py-4 px-2'>
                <img src={header_logo} className='icon-navbar' alt='mh icon'/>
                <span className='font-semibold text-white dark:text-white text-lg'>{header_name}</span>
              </Link>
            </div>
            <div className="hidden md:flex text-center text-white space-x-2 items-center">
              {sections_on_page.map((item, index) => (
                <LinkForComputer path={item.section} key={index} />
              ))}
            </div>
            <div className='hidden md:flex items-center space-x-3 '>
              <ButtonTheme />
            </div>
            <div onClick={() => setIsOpen(!isOpen)} className='md:hidden flex items-center'>
              <button className='outline-none mobile-menu-button'>
                <svg
                  className=' w-6 h-6 text-white hover:text-sky-500 dark:text-white'
                  x-show='!showMenu'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path d='M4 6h16M4 12h16M4 18h16'> </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
       ))}
     {isOpen && (
        <div className="mobile-menu">
          <ul>
            {strapiContent?.map(({sections_on_page, id}) => (
              <div key={id}>
                {sections_on_page.map( item => (
                  <LinkForMobile path={item.section} key={item.section} />
                ))}
              </div>
            ))}
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Header
