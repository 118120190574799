import React, { useState, useEffect } from 'react'
import Locations from './Location'
import { getStrapiUbication } from './data/getStrapiUbication'

const FindUs = () => {
  const [strapiUbication, setStrapiUbication] = useState([])
  const collectionData = 'ubications?[populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiUbication(collectionData)
      setStrapiUbication(data)
    }
    fetchData()
  }, [])

  return (
    <section className='dark:bg-slate-800 mx-auto pt-5 text-center'>
      <h1 className='pb-3 text-1xl font-semibold uppercase md:text-2xl sm:text-1xl text-blue-900 dark:text-sky-500'>Find us here</h1>
      {strapiUbication?.map(({ id,ubication_text, ubication_url }) => (
        <div className='text-center' key={id}>
          <Locations
            maps={ubication_url}
            title={ubication_text}
          />
        </div>
      ))}
    </section>
  )
}

export default FindUs
