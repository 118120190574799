/* eslint-disable */
const token = '7263c587d44ecb3d53143e600006134e66048a9812aee9045550e0c1f8420bd421f22173ce588703f43d011ea493c8333e1b183e9e6d9dcf07e01cbea2a8b59f8e76a38931be1d9db502e09a408abdc31156fc8aeb4abc756064300dd2407f8731f2bfb8cb13ebbbee1f49a13a12f310596244e0272d21ae69e8146935732d99'
const STRAPI_URL = 'https://mh-image-strapi.telluridedigitalworks.com/strapi'

export const getStrapiHeader = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        header_name: attributes.header_title,
        header_logo: STRAPI_URL + attributes.header_image.data.attributes.url,
        sections_on_page: attributes.list_of_sections.sections_on_page.map(({section}) => ({
          id: Math.floor(Math.random() * 100),
          section: section
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
