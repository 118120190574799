const Locations = ({ maps, title }) => {
  return (
    <div >
      <div className='google-maps'>
        <iframe
          title={title}
          src={maps}
          allowFullScreen
          loading='lazy'
        />
      </div>
      <hr />
      <p className='p-5 sm:text-lg text-sm text-blue-900 dark:text-sky-500 uppercase'>
        {title}
      </p>
    </div>
  )
}

export default Locations
